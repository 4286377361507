import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip} from 'chart.js';
import {Navigate, Route, Routes, useSearchParams} from 'react-router-dom';
import {lazy, Suspense, useEffect} from 'react';
import {useLocation} from 'react-router';

import {ApplicationBarContainer, NavigationBarContainer} from '@shared-components';
import {Loader, ModalContainer, NotificationContainer} from '@innowise-group/ui-kit';
import {NavBarCollapseContextProvider} from '@shared/core/services/nav-bar';

import {
  AbilityProvider,
  AppRoutes,
  Can,
  PrivateRoute,
  WorkspaceBookType,
  useAuth,
  useReleaseNotes,
  useSubscription,
} from '@innowise-group/core';

import * as Styled from './app.styles';
import ErrorBoundary from './error-boundary.component';
import {Auth} from './modules/auth';
import {NotFound} from './modules/not-found';

const Offices = lazy(() => import('./modules/offices'));
const Floors = lazy(() => import('./modules/floors'));
const Requests = lazy(() => import('./modules/requests'));
const Administration = lazy(() => import('./modules/administration'));
const History = lazy(() => import('./modules/history'));
const Employees = lazy(() => import('./modules/employees'));
const Geotrack = lazy(() => import('./modules/geotrack'));
const Hrm = lazy(() => import('./modules/hrm'));

const FloorEditor = lazy(() => import('./modules/floor-editor'));
// const Chatbot = lazy(() => import('./modules/chatbot'));

const components = [
  {path: AppRoutes.Offices, Component: Offices, allow: true, access: null},
  {path: AppRoutes.Floors, Component: Floors, allow: true, access: null},
  {path: AppRoutes.Requests, Component: Requests, allow: true, access: null},
  {path: AppRoutes.Managers, Component: Administration, allow: true, access: null},
  {path: AppRoutes.History, Component: History, allow: false, access: 'CHANGES_READ'},
  {path: AppRoutes.Employees, Component: Employees, allow: false, access: 'EMPLOYEES_READ'},
  {path: AppRoutes.Geotrack, Component: Geotrack, allow: false, access: 'GEOTRACK_READ'},

  {path: AppRoutes.FloorEditor, Component: FloorEditor, allow: true, access: null},

  // {path: AppRoutes.Chatbot, Component: Chatbot},
];

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const App = () => {
  const {initialized, logout} = useAuth();
  const initializedValue = useSubscription(initialized);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    function upper(value: string) {
      const path = value.match(/(^\/)+([a-z,-]*)/)[2];
      return path.charAt(0).toUpperCase() + path.slice(1);
    }
    document.title = `ITS Hotelling: ${upper(location.pathname)}`;
  }, [location.pathname]);

  useEffect(() => {
    if (searchParams.has('id')) {
      sessionStorage.setItem('requestId', searchParams.get('id'));
    }
  }, [searchParams]);

  const {modalsReadyCallback, open: openReleaseNotes} = useReleaseNotes();

  if (!initializedValue) {
    return <Loader />;
  }

  const checkAccessByWorkspaceStatusAndAllowed = (allowed: boolean) => (workspaceStatus: WorkspaceBookType) =>
    allowed || Boolean(workspaceStatus);

  return (
    <NavBarCollapseContextProvider>
      <AbilityProvider>
        <Routes>
          <Route path={AppRoutes.Auth} element={<Auth openReleaseNotes={openReleaseNotes} />} />
          <Route
            path={`${AppRoutes.Hrm}/*`}
            element={
              <PrivateRoute>
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <Hrm />
                  </Suspense>
                </ErrorBoundary>
              </PrivateRoute>
            }
          />
          <Route
            path={`${AppRoutes.Home}*`}
            element={
              <PrivateRoute>
                <Styled.VerticalContainer id="application-container">
                  <Can I="APPROVE" a="WORKSPACE-REQUEST" passThrough>
                    {(allowed) => (
                      <ApplicationBarContainer
                        onLogout={logout}
                        checkAccessByWorkspaceStatus={checkAccessByWorkspaceStatusAndAllowed(allowed)}
                      />
                    )}
                  </Can>
                  <Styled.AppContainer>
                    <NavigationBarContainer openReleaseNotes={openReleaseNotes} />
                    <Styled.ContentContainer>
                      <Routes>
                        {components.map(({Component, path, allow, access}) => {
                          const accessParts = (access ?? 'WORKSPACE-REQUEST_APPROVE').split('_');
                          return (
                            <Route
                              key={path}
                              path={`${path}/*`}
                              element={
                                <Can I={accessParts[1]} a={accessParts[0]} passThrough>
                                  {(allowed) => {
                                    return (
                                      <>
                                        {allow || allowed ? (
                                          <ErrorBoundary>
                                            <Suspense fallback={<Loader />}>
                                              <Component />
                                            </Suspense>
                                          </ErrorBoundary>
                                        ) : (
                                          <Navigate to={AppRoutes.Offices} />
                                        )}
                                      </>
                                    );
                                  }}
                                </Can>
                              }
                            />
                          );
                        })}
                        <Route path={`${AppRoutes.Home}/*`} element={<Navigate to={AppRoutes.Offices} />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Styled.ContentContainer>
                  </Styled.AppContainer>
                </Styled.VerticalContainer>
              </PrivateRoute>
            }
          />
          <Route path={AppRoutes.NotFound} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ModalContainer notifyWhenReady={modalsReadyCallback} />
        <NotificationContainer />
      </AbilityProvider>
    </NavBarCollapseContextProvider>
  );
};

export default App;
