import {injectable} from 'inversify';
import Keycloak, {KeycloakLoginOptions} from 'keycloak-js';
import {BehaviorSubject} from 'rxjs';
import {GlobalContainerTypes} from '../../../types';
import {Auth, AuthState, Profile} from './auth.types';

@injectable()
class AuthService implements Auth {
  public static readonly type = GlobalContainerTypes.Auth;

  private client: Keycloak;
  public initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authenticated: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public constructor() {
    const filePath = '/keycloak.json';
    this.client = new Keycloak(filePath);

    this.client.init({
      onLoad: 'check-sso',
      // checkLoginIframe: false,
    });

    this.client.onReady = (authenticated: boolean) => {
      this.initialized.next(true);
      this.authenticated.next(authenticated);
    };

    this.client.onAuthSuccess = () => {
      this.authenticated.next(true);
    };

    this.client.onAuthError = () => {
      this.authenticated.next(false);
    };

    this.client.onAuthRefreshError = () => {
      this.client.clearToken();
      this.logout();
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  public async login(options?: KeycloakLoginOptions): Promise<void> {
    this.client.login(options);
  }

  public updateToken() {
    return this.client.updateToken(5);
  }

  public logout(): void {
    this.client.logout();
  }

  public get authState(): AuthState {
    return {
      accessToken: this.client.token,
      refreshToken: this.client.refreshToken,
      idToken: this.client.idToken,
    };
  }

  public get userProfile(): Profile {
    return {
      preferredName: this.client.tokenParsed?.name,
      department: this.client.tokenParsed?.azp,
      avatarUrl: this.client.tokenParsed?.avatarUrl,
    };
  }
}

export default AuthService;
